/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from "react-router-dom";
import InvoicePage from "../../components/invoice/InvoicePage";
import "./Invoice.scss";
import { useInvoiceService } from "../../services/invoiceService";
import { useCallback, useEffect, useState } from "react";
import { useUserService } from "../../services/userService";
import dayjs from "dayjs";
import { Button, Card, Popconfirm, Spin, notification } from "antd";
import {
	CheckCircleOutlined,
	DeleteOutlined,
	PlusCircleFilled,
} from "@ant-design/icons";
import { initialInvoice } from "../../components/invoice/data/initialData";
import { useAuth } from "../../context/AuthContext";
import { ADMIN_ROLES } from "../../common/constants/adminRoles";

const dateFormat = "DD-MM-YYYY";

function Invoice() {
	const { currentUser } = useAuth();
	const { userId, date } = useParams();
	const invoiceService = useInvoiceService();
	const userService = useUserService();

	const [invoicesData, setInvoicesData] = useState([]);
	const [userData, setUserData] = useState({});
	const [selectedInvoice, setSelectedInvoice] = useState({});
	const [isNewInvoice, setIsNewInvoice] = useState(false);
	const [showRemoveButton, setShowRemoveButton] = useState(false);
	const [isOnClickRemoveItem, setIsOnClickRemoveItem] = useState(false);

	const [isInvoiceLoading, setIsInvoiceLoading] = useState(true);
	const [isInvoiceSendMessageLoading, setIsInvoiceSendMessageLoading] =
		useState(false);

	useEffect(() => {
		fetchInvoice();
	}, []);

	const fetchInvoice = useCallback(async () => {
		try {
			setIsInvoiceLoading(true);

			const invoices = currentUser?.userRoleNames?.some((role) =>
				ADMIN_ROLES.includes(role)
			)
				? await invoiceService.getAllInvoicesByMonth(
						userId,
						dayjs(date, dateFormat)
				  )
				: await invoiceService.getAllUserInvoicesByMonth(
						dayjs(date, dateFormat)
				  );

			const user = currentUser?.userRoleNames?.some((role) =>
				ADMIN_ROLES.includes(role)
			)
				? (await userService.getUserById(userId)).data
				: currentUser;

			setInvoicesData(invoices.data);
			setUserData(user);
			if (invoices?.data?.length) {
				setSelectedInvoice({
					...invoices.data[0],
					productLines: invoices.data[0].productLines || [],
				});
				setIsNewInvoice(false);
				return;
			}
			setSelectedInvoice({
				...initialInvoice,
				clientName: `${user.firstName} ${user.lastName}`,
				productLines: createProductLines(user),
				id: "new",
			});
			setIsNewInvoice(true);
		} catch (e) {
			notification.error({
				message: "Error",
				description: e.message,
				placement: "bottomRight",
			});
		} finally {
			setIsInvoiceLoading(false);
		}
	}, []);

	const saveInvoice = async (data) => {
		try {
			const updateInovoice = invoicesData.find(
				(invoice) => invoice.id === data.id
			);

			const invoice = { ...data, userId: userData.id };

			if (updateInovoice) {
				await invoiceService.updateInvoice(data.id, invoice);
			} else {
				await invoiceService.createInvoice({
					...invoice,
					user: userData,
				});
			}

			await fetchInvoice();
		} catch (e) {
			console.log(e);

			notification.error({
				message: "Error",
				description: e.response.data.message,
				placement: "bottomRight",
			});
		}
	};

	const downloadInvoice = async (id, fileName) => {
		try {
			await invoiceService.downloadPdfInvoice(id, fileName);
		} catch (e) {
			notification.error({
				message: "Error",
				description: e.message,
				placement: "bottomRight",
			});
		}
	};

	const sendInvoiceMessage = async (invoice) => {
		try {
			setIsInvoiceSendMessageLoading(true);
			await invoiceService.sendInvoiceMessage(invoice.id, invoice);
			notification.success({
				message: "Mail was successfully sent!",
				placement: "bottomRight",
			});
			await fetchInvoice();
		} catch (e) {
			notification.error({
				message: "Error",
				description: e.message,
				placement: "bottomRight",
			});
		} finally {
			setIsInvoiceSendMessageLoading(false);
		}
	};

	const onClickInvoiceCard = (invoice) => {
		setSelectedInvoice({
			...invoice,
			productLines: invoice.productLines || [],
		});
		setIsNewInvoice(false);
	};

	const onClickAddInvoice = () => {
		setSelectedInvoice({
			...initialInvoice,
			clientName: `${userData.firstName} ${userData.lastName}`,
			productLines: createProductLines(userData),
			id: "new",
		});
		setIsNewInvoice(true);
	};

	const mouseHover = (invoice) => {
		setShowRemoveButton(invoice);
	};

	const onClickRemoveInvoice = async (id) => {
		try {
			await invoiceService.removeInvoice(id);
			setIsOnClickRemoveItem(false);
			await fetchInvoice();
		} catch (e) {
			notification.error({
				message: "Error",
				description: e.message,
				placement: "bottomRight",
			});
		}
	};

	const createProductLines = (user) =>
		user?.userProjects?.map((userProject) => ({
			description: `${userProject?.project.name} Software Development`,
			quantity: userProject?.monthWorkingHours ?? 0,
			rate:
				user?.userHourRates.find(
					(userHourRate) =>
						userHourRate.project.id === userProject.projectId
				)?.rate ?? 1,
			amount:
				user?.userHourRates.find(
					(userHourRate) =>
						userHourRate.project.id === userProject.projectId
				)?.amount ?? 1,
		}));

	return (
		<div className="app">
			{isInvoiceLoading ? (
				<Spin />
			) : (
				<div style={{ display: "flex" }}>
					<div style={{ marginRight: 15 }}>
						{currentUser?.userRoleNames?.some((role) =>
							ADMIN_ROLES.includes(role)
						) && (
							<Card
								hoverable
								style={{
									width: 220,
									padding: 0,
									marginBottom: 7,
									border:
										selectedInvoice.id === "new" &&
										"1px solid #4cffe9",
								}}
								className="invoice-card"
								onClick={() => onClickAddInvoice()}
							>
								<div
									style={{
										fontWeight: 600,
										fontSize: 16,
										display: "flex",
										alignItems: "center",
									}}
								>
									<PlusCircleFilled
										style={{
											fontSize: 25,
											marginRight: 10,
											color: "#1677ff",
										}}
									/>
									Add invoice
								</div>
							</Card>
						)}
						{invoicesData.map((invoice) => (
							<Card
								onMouseEnter={() => mouseHover(invoice)}
								onMouseLeave={() =>
									!isOnClickRemoveItem && mouseHover({})
								}
								key={invoice.id}
								hoverable
								style={{
									width: 220,
									border:
										invoice.id === selectedInvoice.id &&
										"1px solid #4cffe9",
									padding: 0,
									marginBottom: 7,
								}}
								className="invoice-card"
								onClick={() => onClickInvoiceCard(invoice)}
							>
								<div>
									<Popconfirm
										title="Delete the invoice?"
										description="Are you sure to delete this invoice?"
										onConfirm={() => {
											onClickRemoveInvoice(invoice?.id);
										}}
										onCancel={() =>
											setIsOnClickRemoveItem(false)
										}
										okText="Yes"
										cancelText="No"
									>
										{currentUser?.userRoleNames?.some(
											(role) => ADMIN_ROLES.includes(role)
										) &&
										showRemoveButton?.id === invoice?.id ? (
											<Button
												danger
												style={{
													position: "absolute",
													right: 5,
													top: 5,
												}}
												onClick={() => {
													setIsOnClickRemoveItem(
														true
													);
												}}
												icon={<DeleteOutlined />}
											/>
										) : null}
									</Popconfirm>
									Invoice #{" "}
									<div
										style={{
											fontWeight: 600,
											display: "inline-block",
										}}
									>
										{invoice.invoiceName}
									</div>
								</div>
								<div>
									UPD:{" "}
									<div
										style={{
											fontWeight: 600,
											display: "inline-block",
										}}
									>
										{dayjs(invoice.updated_at).format(
											"DD-MM-YYYY HH:mm"
										)}
									</div>
								</div>
								<div>
									Sent:{" "}
									<div
										style={{
											fontWeight: 600,
											display: "inline-block",
											color:
												invoice.sendingDate && "green",
										}}
									>
										{invoice.sendingDate ? (
											<>
												{`${dayjs(
													invoice.sendingDate
												).format("DD-MM-YYYY HH:mm")}
												`}
												<CheckCircleOutlined
													style={{
														marginLeft: 3,
														fontSize: 17,
													}}
												/>
											</>
										) : (
											"-"
										)}
									</div>
								</div>
							</Card>
						))}
					</div>

					<InvoicePage
						data={selectedInvoice}
						saveInvoice={saveInvoice}
						isNewInvoice={isNewInvoice}
						downloadInvoice={downloadInvoice}
						sendInvoiceMessage={sendInvoiceMessage}
						isInvoiceSendMessageLoading={
							isInvoiceSendMessageLoading
						}
					/>
				</div>
			)}
		</div>
	);
}

export default Invoice;
