import logo from "../../assets/logo_intercode.png";
import "./loaderStyles.scss";

function AppLoader() {
	return (
		<div className="wrapper">
			<div className="logo_container">
				<img alt="" width={90} src={logo} />
			</div>
		</div>
	);
}

export default AppLoader;
