import {
	Button,
	Table,
	Avatar,
	notification,
	Modal,
	Form,
	Input,
	Divider,
	Typography,
	Tag,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import { useUserService } from "../../services/userService";
import SignInAdminButton from "../../components/auth/SignInAdminButton";
import "./UserList.scss";
import { LOGIN_ADMIN_SCOPES } from "../../common/constants/googleScopes";
import { useProjectService } from "../../services/projectService";
import UserEditModal from "../../components/usersList/UserEditModal";

function UsersList() {
	const userService = useUserService();
	const projectService = useProjectService();

	const [users, setUsers] = useState();
	const [userRoles, setUserRoles] = useState();
	const [projects, setProjects] = useState([]);
	const [selectedProjects, setSelectedProjects] = useState([]);

	const [isUsersLoading, setIsUsersLoading] = useState(false);
	const [isUserEditLoading, setIsUserEditLoading] = useState(false);
	const [editUser, setEditUser] = useState({});

	const [prevValue, setPrevValue] = useState();

	const [visibleAddUserModal, setVisibleAddUserModal] = useState(false);
	const [visibleEditUserModal, setVisibleEditUserModal] = useState(false);

	const [userAddForm] = Form.useForm();

	const handleChangeProjects = (value, label) => {
		setSelectedProjects(
			selectedProjects.map((project, index) => {
				if (project.value === value) {
					project.disabled = true;
				}
				if (project.value === prevValue) {
					project.disabled = false;
				}

				return project;
			})
		);
	};

	const handleRemoveProject = (key) => {
		setSelectedProjects(
			selectedProjects.map((project, index) => {
				if (project.value === key) {
					project.disabled = false;
				}

				return project;
			})
		);
	};

	useEffect(() => {
		setIsUsersLoading(true);

		fetchUsers();
		fetchUserRoles();
		fetchProjects();
	}, []);

	const fetchUsers = useCallback(async () => {
		try {
			const { data } = await userService.getAllUsers();

			setUsers(
				data.map((user) => ({
					...user,
					name: `${user.firstName} ${user.lastName}`,
					roleNames: user.userRoles.map(
						(userRole) => userRole.role.name
					),
					roleIds: user.userRoles.map((userRole) => userRole.role.id),
					key: user.id,
					userProjects: user.userProjects?.map((userProject) => ({
						name: userProject.project.name,
						workType: userProject.workType,
						projectId: userProject.projectId,
						dayWorkingHours: userProject.dayWorkingHours,
					})),
				}))
			);
		} catch (e) {
			console.error(e);
		}
	}, []);

	const fetchUserRoles = useCallback(async () => {
		try {
			const { data } = await userService.getUserRoles();

			setUserRoles(
				data.map((role) => ({
					label: role.name,
					value: role.id,
				}))
			);
		} catch (e) {
			console.error(e);
		}
	}, []);

	const fetchProjects = useCallback(async () => {
		try {
			const { data } = await projectService.getAllProjects();

			const projects = data.map((project) => ({
				value: project.id,
				label: project.name,
			}));

			setProjects(projects);
		} catch (e) {
			console.error(e);
		} finally {
			setIsUsersLoading(false);
		}
	}, []);

	const columns = [
		{
			title: "",
			dataIndex: "photo",
			key: "photo",
			width: "50px",
			render: (photo) => <Avatar size="large" src={`${photo}`} />,
		},
		{
			title: "Name",
			dataIndex: "name",
			// specify the condition of filtering result
			// here is that finding the name started with `value`
			onFilter: (value, record) => record.name.indexOf(value) === 0,
			sorter: (a, b) => a.name.length - b.name.length,
			sortDirections: ["descend"],
			key: "name",
			width: "250px",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			width: "300px",
		},
		{
			title: "Role",
			dataIndex: "roleNames",
			key: "roleNames",
			width: "250px",
			render: (_, { roleNames }) => (
				<>
					{roleNames?.map((tag) => (
						<Tag color={"blue"} key={tag}>
							{tag.toUpperCase()}
						</Tag>
					))}
				</>
			),
		},
		{
			title: "Project",
			dataIndex: "userProjects",
			key: "userProjects",
			render: (_, { userProjects }) => (
				<>
					{userProjects?.map((tag) => (
						<Tag
							color={
								tag.workType === "full-time" ? "green" : "cyan"
							}
							key={tag.name}
						>
							{tag.name.toUpperCase()}
						</Tag>
					))}
				</>
			),
		},
		{
			title: "Actions",
			dataIndex: "actions",
			key: "actions",
			width: "10%",
			render: (text, record) => (
				<div>
					<Button
						onClick={() => onClickUserEdit(record)}
						icon={<EditOutlined />}
					/>
					<Button danger icon={<DeleteOutlined />} className="ml-1" />
				</div>
			),
		},
	];

	const onClickUserEdit = (info) => {
		setEditUser(info);
		const foundSelectedProjects = projects.map((project, idx) => ({
			value: project.value,
			label: project.label,
			disabled: info.userProjects.some(
				(userProject) => userProject.projectId === project.value
			),
		}));

		setSelectedProjects(foundSelectedProjects);

		setVisibleEditUserModal(true);
	};

	const onChange = (pagination, filters, sorter, extra) => {
		console.log("params", pagination, filters, sorter, extra);
	};

	const onClickImportUsersFromGoogle = async () => {
		try {
			setIsUsersLoading(true);
			await userService.importGoogleUsers();
			await fetchUsers();
			setIsUsersLoading(false);
		} catch (error) {
			console.log(error.message);
			setIsUsersLoading(false);
			notification.error({
				message: "Error",
				description: error.message,
				placement: "bottomRight",
			});
		}
	};

	const showModal = () => {
		setVisibleAddUserModal(true);
	};

	const handleSubmitAddUser = async (values) => {
		values.email += "@intercode.io";

		try {
			await userService.addGoogleUser(values);
			fetchUsers();
			setVisibleAddUserModal(false);
			userAddForm.resetFields();
		} catch (error) {
			console.log(error);
		}
	};

	const handleSubmitEditUser = async (values) => {
		try {
			setIsUserEditLoading(true);
			await userService.updateUser({
				id: editUser.id,
				roleIds: values.roleIds ?? [],
				projectIds: values.projectIds ?? [],
			});
			fetchUsers();
			setIsUserEditLoading(false);

			setVisibleEditUserModal(false);
			setSelectedProjects([]);
		} catch (error) {
			console.log(error);
		}
	};

	const handleUserAddCancel = () => {
		setVisibleAddUserModal(false);
		userAddForm.resetFields();
	};

	return (
		<>
			<div className="buttons-section">
				<div>
					<Button type="primary" onClick={showModal}>
						Add new user
					</Button>
					<Modal
						className="add-user-modal"
						open={visibleAddUserModal}
						onOk={userAddForm.submit}
						onCancel={handleUserAddCancel}
						width={800}
					>
						<Divider style={{ marginTop: 40 }}>
							<Typography.Title
								level={4}
								style={{ margin: "auto" }}
							>
								User Information
							</Typography.Title>
						</Divider>
						<Form
							form={userAddForm}
							style={{ paddingTop: 10 }}
							onFinish={handleSubmitAddUser}
						>
							<Form.Item style={{ marginBottom: 5 }}>
								<Form.Item
									name="firstName"
									rules={[{ required: true }]}
									style={{
										display: "inline-block",
										width: "calc(50% - 5px)",
									}}
								>
									<Input
										size="large"
										placeholder="First name *"
									/>
								</Form.Item>
								<Form.Item
									name="lastName"
									rules={[{ required: true }]}
									style={{
										display: "inline-block",
										width: "calc(50% - 5px)",
										marginLeft: "10px",
									}}
								>
									<Input
										size="large"
										placeholder="Last name *"
									/>
								</Form.Item>
							</Form.Item>
							<Form.Item style={{ marginBottom: 5 }}>
								<Form.Item
									name="email"
									rules={[{ required: true }]}
									style={{
										display: "inline-block",
										width: "100%",
									}}
								>
									<Input
										size="large"
										placeholder="Primary email *"
										addonAfter="@intercode.io"
									/>
								</Form.Item>
							</Form.Item>
							<Form.Item style={{ marginBottom: 5 }}>
								<Form.Item
									name="secondaryEmail"
									rules={[{ required: false }]}
									style={{
										display: "inline-block",
										width: "calc(50% - 5px)",
									}}
								>
									<Input
										size="large"
										placeholder="Secondary email"
									/>
								</Form.Item>
								<Form.Item
									name="phone"
									rules={[{ required: false }]}
									style={{
										display: "inline-block",
										width: "calc(50% - 5px)",
										marginLeft: "10px",
									}}
								>
									<Input
										size="large"
										placeholder="Phone number"
									/>
								</Form.Item>
							</Form.Item>
							<Form.Item style={{ marginBottom: 5 }}>
								<Form.Item
									name="password"
									rules={[{ required: true }]}
									style={{
										display: "inline-block",
										width: "100%",
									}}
								>
									<Input.Password
										size="large"
										placeholder="Password (more than 8 symbols) *"
									/>
								</Form.Item>
							</Form.Item>
						</Form>
					</Modal>
					<Button
						className="ml-1"
						onClick={onClickImportUsersFromGoogle}
					>
						Import users from GSuite
					</Button>
				</div>
				<SignInAdminButton scope={LOGIN_ADMIN_SCOPES} />
			</div>
			<div>
				<Table
					columns={columns}
					dataSource={users}
					onChange={onChange}
					loading={isUsersLoading}
					pagination={false}
				/>
				<UserEditModal
					visibleEditUserModal={visibleEditUserModal}
					setVisibleEditUserModal={setVisibleEditUserModal}
					editUser={editUser}
					userRoles={userRoles}
					handleSubmitEditUser={handleSubmitEditUser}
					handleChangeProjects={handleChangeProjects}
					projects={projects}
					selectedProjects={selectedProjects}
					handleRemoveProject={handleRemoveProject}
					setSelectedProjects={setSelectedProjects}
					isUserEditLoading={isUserEditLoading}
					setPrevValue={setPrevValue}
				/>
			</div>
		</>
	);
}

export default UsersList;
