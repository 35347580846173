import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { ADMIN_ROLES } from "../common/constants/adminRoles";

const ProtectedRoute = ({ redirectPath = "/compensation", children }) => {
	const { currentUser } = useContext(AuthContext);

	if (
		!currentUser?.userRoleNames?.some((role) => ADMIN_ROLES.includes(role))
	) {
		return <Navigate to={redirectPath} replace />;
	}

	return children ? children : <Outlet />;
};

export default ProtectedRoute;
