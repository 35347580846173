import { Avatar, Dropdown, Menu } from "antd";
import { SettingOutlined, UserOutlined } from "@ant-design/icons";
import { useAuth } from "../../context/AuthContext";
import userImage from "../../assets/user-image.jpg";

function UserIconDropdown() {
	const { logout, currentUser } = useAuth();

	const handleMenuClick = (e) => {
		switch (e.key) {
			case "logout":
				logout();
				break;
			case "profile":
				break;
			case "settings":
				break;
			default:
				break;
		}
	};

	const menu = (
		<Menu onClick={handleMenuClick}>
			<Menu.Item key="profile">
				<UserOutlined />
				<span className="ml-1">Profile</span>
			</Menu.Item>
			<Menu.Item key="settings">
				<SettingOutlined />
				<span className="ml-1">Settings</span>
			</Menu.Item>
			<Menu.Divider></Menu.Divider>
			<Menu.Item key="logout">Logout</Menu.Item>
		</Menu>
	);

	return (
		<Dropdown overlay={menu} placement="bottomRight">
			<Avatar
				src={currentUser?.photo !== "" ? currentUser?.photo : userImage}
				icon={<img src={userImage} alt="" />}
				style={{
					cursor: "pointer",
					color: "#f56a00",
					width: 37,
					height: 37,
				}}
			/>
		</Dropdown>
	);
}

export default UserIconDropdown;
