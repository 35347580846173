import {Card, Col, Row, List, Avatar, Space, Tooltip, Typography, Input, Image, Divider, Button} from "antd";
import {
    GiftOutlined,
    GithubOutlined,
    GitlabOutlined,
    LinkedinOutlined,
    SlackOutlined, SolutionOutlined,
    UserOutlined
} from "@ant-design/icons";
import './Dashboard.scss';

const {TextArea} = Input;
const {Text, Title} = Typography;
const {Meta} = Card;

function Dashboard() {
    const tasks = [
        'First task goes here',
        'Second task goes here',
        'Third task is here'
    ];

    const peopleStatus = [
        'Vacation',
        'Working from Home',
        'Sick',
        'Personal Leave'
    ];

    return (
        <>
            <Title level={3}>Hi, Adrian Barbe, glad you're here!</Title>
            <Row gutter={[16, 16]}>
                <Col span={18}>
                    <Card title="Recent Tasks"
                          bordered={true}
                          actions={[
                              <div>View all</div>,
                          ]}>
                        <List
                            dataSource={tasks}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />
                    </Card>
                    <Card className='mt-1' title="Upcoming Birthdays"
                          bordered={true}>
                        <Row justify='space-around' gutter={[16, 16]}>
                            {
                                [0,1,2,3].map((_, index) => {
                                    return (
                                        <Col key={index} span={6}>
                                            <Card>
                                                <div className='birthday-card-wrapper'>
                                                    <Avatar size={64} icon={<UserOutlined/>}/>
                                                    <Title level={4}>John Doe</Title>
                                                    <Text>Name of position</Text>
                                                    <div className='mt-1'>
                                                        <Button>Send wishes</Button>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </Card>
                    <Divider/>
                    <Card className='mt-1'
                          bordered={true}
                          actions={[
                              <div>
                                  <GiftOutlined/>
                                  <span className='ml-1'>Kudos someone</span>
                              </div>,
                              <div>
                                  <SolutionOutlined/>
                                  <span className='ml-1'>Announcement</span>
                              </div>,
                          ]}>
                        <Row gutter={[16, 16]}>
                            <Col span={2}>
                                <Avatar size={50} icon={<UserOutlined/>}/>
                            </Col>
                            <Col span={22}>
                                <TextArea
                                    placeholder="Put down here something..."
                                    autoSize={{minRows: 2, maxRows: 6}}
                                />
                            </Col>
                        </Row>
                    </Card>
                    <Card
                        className='mt-1'
                    >
                        <Meta
                            avatar={<Avatar src="https://joeschmoe.io/api/v1/random"/>}
                            title="John Doe"
                            description="Today at 10:53 AM"
                        />
                        <div className='mt-1'>
                            <Text>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vulputate commodo metus ut
                                congue. Donec semper vestibulum quam. Donec sodales quam nec sodales fermentum. Vivamus
                                porta risus ac elit lobortis vehicula. Curabitur a feugiat erat. Cras fringilla diam
                                nunc, ac mattis mauris vehicula a. Nullam id libero ligula. Sed vel pharetra elit, in
                                volutpat nulla. Nam aliquet tellus ac mattis ullamcorper.

                                Nullam ac tincidunt diam. Nunc ipsum nisl, porta sed porta imperdiet, pretium sed leo.
                                Suspendisse quis nulla vel magna consequat maximus. Cras eleifend, odio sit amet euismod
                                facilisis, eros libero dapibus lectus, mattis dapibus purus eros ut lectus. Morbi
                                hendrerit dapibus tellus. Morbi malesuada urna nec lectus faucibus tempus. Donec
                                accumsan egestas libero eu molestie. Aliquam erat volutpat. Sed aliquam dui in quam
                                egestas, nec laoreet libero semper.
                            </Text>
                            <Image
                                src='https://as2.ftcdn.net/v2/jpg/03/13/59/77/1000_F_313597729_tnfUcGjYpj12WFeJan5zMpUKMdeB6fCq.jpg'/>
                        </div>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="Quick links" bordered={true}>
                        <Space>
                            <Avatar size={64} icon={<GithubOutlined/>}/>

                            <Avatar size={64} icon={<SlackOutlined/>}/>

                            <Avatar size={64} icon={<GitlabOutlined/>}/>

                            <Avatar size={64} icon={<LinkedinOutlined/>}/>
                        </Space>
                    </Card>

                    <Card className="mt-1"
                          title="People's status"
                          bordered={true}
                          actions={[
                              <div>View all</div>,
                          ]}
                    >
                        <List
                            dataSource={peopleStatus}
                            size="large"
                            renderItem={item => (
                                <List.Item>
                                    <Row style={{flexGrow: 1}} justify='space-between' align='middle'>
                                        <div>{item}</div>
                                        <div>
                                            <Avatar.Group
                                                maxCount={2}
                                                size="large"
                                                maxStyle={{color: '#f56a00', backgroundColor: '#fde3cf'}}
                                            >
                                                <Avatar icon={<UserOutlined/>}/>
                                                <Avatar icon={<UserOutlined/>}/>
                                                <Avatar icon={<UserOutlined/>}/>
                                                <Tooltip title="More..." placement="top">
                                                    <Avatar icon={<UserOutlined/>}/>
                                                    <Avatar icon={<UserOutlined/>}/>
                                                </Tooltip>
                                            </Avatar.Group>
                                        </div>
                                    </Row>
                                </List.Item>
                            )}
                        />
                    </Card>
                    <Card className="mt-1"
                          title="Job Anniversaries"
                          bordered={true}
                    >
                        <Row justify='space-around' gutter={[5, 5]}>
                            <Col span={6} style={{textAlign: 'center'}}>
                                <Avatar size={64} icon={<UserOutlined/>}/>
                                <Text strong>Valentyn S.</Text>
                                <div>
                                    <Text>5 years</Text>
                                </div>
                            </Col>
                            <Col span={6} style={{textAlign: 'center'}}>
                                <Avatar size={64} icon={<UserOutlined/>}/>
                                <Text strong>Adrian B.</Text>
                                <div>
                                    <Text>5 years</Text>
                                </div>
                            </Col>
                            <Col span={6} style={{textAlign: 'center'}}>
                                <Avatar size={64} icon={<UserOutlined/>}/>
                                <Text strong>Dmytro M.</Text>
                                <div>
                                    <Text>5 years</Text>
                                </div>
                            </Col>
                            <Col span={6} style={{textAlign: 'center'}}>
                                <Avatar size={64} icon={<UserOutlined/>}/>
                                <Text strong>Yura P.</Text>
                                <div>
                                    <Text>5 years</Text>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default Dashboard;
