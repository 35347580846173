import { createContext, useCallback, useContext, useState } from "react";
import { useLocalStorage } from "../common/hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { useUserService } from "../services/userService";
import {
	REFRESH_TOKEN,
	TOKEN,
	USER_ROLES,
} from "../common/constants/localStorage";
import { updateToken } from "../services/tokenService";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
	const [token, setToken] = useLocalStorage(TOKEN, null);
	const [refreshToken, setRefreshToken] = useLocalStorage(
		REFRESH_TOKEN,
		null
	);
	const [currentUser, setCurrentUser] = useState();
	const [isUserLoading, setIsUserLoading] = useState(true);

	const navigate = useNavigate();
	const userService = useUserService();

	const fetchUser = useCallback(async () => {
		try {
			if (token) {
				setIsUserLoading(true);
				const { data } = await userService.getUser();
				const updatedUser = {
					...data,
					userRoleNames: data?.userRoles.map(
						(role) => role.role.name
					),
				};
				setCurrentUser(updatedUser);
				updateToken(USER_ROLES, updatedUser?.userRoleNames);
				setTimeout(() => {
					setIsUserLoading(false);
				}, 1000);
			}
		} catch (e) {
			console.error(e);
		}
	}, []);

	const setAuth = (token, refreshToken) => {
		setToken(token);
		setRefreshToken(refreshToken);
	};

	const logout = () => {
		setToken(null);

		navigate("/auth/sign-in");
	};

	return (
		<AuthContext.Provider
			value={{
				token,
				setAuth,
				logout,
				fetchUser,
				currentUser,
				isUserLoading,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	return useContext(AuthContext);
};
