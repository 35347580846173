import React from "react";
import { Menu } from "antd";
import {
	AppstoreOutlined,
	BulbOutlined,
	CalendarOutlined,
	CheckSquareOutlined,
	ClockCircleOutlined,
	DollarOutlined,
	FolderOutlined,
	SettingOutlined,
	StockOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { ADMIN_ROLES } from "../constants/adminRoles";

function LeftMenu() {
	const navigate = useNavigate();
	const location = useLocation();
	const { currentUser } = useAuth();

	const leftMenu = [
		// {
		// 	key: "/dashboard",
		// 	icon: React.createElement(AppstoreOutlined),
		// 	label: "Dashboard",
		// },
		currentUser?.userRoleNames?.some((role) =>
			ADMIN_ROLES.includes(role)
		) && {
			key: "users",
			icon: React.createElement(UserOutlined),
			label: "People",
			children: [
				{
					key: "/users-list",
					label: "Users list",
				},
				// {
				// 	key: "/users-privileges",
				// 	label: "Users' Privileges",
				// },
			],
		},
		// {
		// 	key: "/reporting",
		// 	icon: React.createElement(ClockCircleOutlined),
		// 	label: "Reporting",
		// },
		// {
		// 	key: "/leaves",
		// 	icon: React.createElement(CalendarOutlined),
		// 	label: "Leaves & Sickness",
		// 	children: [
		// 		{
		// 			key: "/leaves-settings",
		// 			label: "Settings",
		// 		},
		// 		{
		// 			key: "/leaves-overview",
		// 			label: "Overview",
		// 		},
		// 		{
		// 			key: "/leaves-calendar",
		// 			label: "Calendar",
		// 		},
		// 	],
		// },
		// {
		// 	key: "/grow_up",
		// 	icon: React.createElement(StockOutlined),
		// 	label: "Grow-up",
		// },
		// {
		// 	key: "/docs",
		// 	icon: React.createElement(FolderOutlined),
		// 	label: "Documents",
		// },
		// {
		// 	key: "/tasks",
		// 	icon: React.createElement(CheckSquareOutlined),
		// 	label: "Tasks",
		// },
		{
			key: "/compensation",
			icon: React.createElement(DollarOutlined),
			label: "Compensation",
		},
		// {
		// 	key: "/benefits",
		// 	icon: React.createElement(BulbOutlined),
		// 	label: "Corporate Benefits",
		// },
		currentUser?.userRoleNames?.some((role) =>
			ADMIN_ROLES.includes(role)
		) && {
			key: "/settings",
			icon: React.createElement(SettingOutlined),
			label: "Settings & Dictionaries",
			children: [
				// {
				// 	key: "/departments",
				// 	label: "Departments",
				// },
				{
					key: "/projects",
					label: "Projects",
				},
				{
					key: "/hour-rate",
					label: "Hourly rates",
				},
			],
		},
	];

	const onMenuClick = ({ key }) => {
		navigate(key);
	};

	return (
		<Menu
			onClick={onMenuClick}
			mode="inline"
			defaultSelectedKeys={["1"]}
			selectedKeys={[location.pathname]}
			defaultOpenKeys={["sub1"]}
			style={{ height: "100%", borderRight: 0 }}
			items={leftMenu}
		/>
	);
}

export default LeftMenu;
