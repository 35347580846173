import { useAxios } from "./axiosService";

export const useInvoiceService = () => {
	const { axios } = useAxios();

	const getInvoiceById = async (id) => {
		return await axios.get(`/invoices/${id}`);
	};
	const getAllInvoices = async () => {
		return await axios.get("/invoices");
	};
	const getAllInvoicesByMonth = async (userId, date) => {
		return await axios.get(`/invoices/month?userId=${userId}&date=${date}`);
	};
	const getAllUserInvoicesByMonth = async (date) => {
		return await axios.get(`/invoices/user/month?date=${date}`);
	};
	const createInvoice = async (body) => {
		return await axios.post("/invoices", body);
	};
	const sendInvoiceMessage = async (id, body) => {
		return await axios.post(`/invoices/send-message/${id}`, body);
	};
	const updateInvoice = async (id, body) => {
		await axios.put(`/invoices/${id}`, body);
	};
	const removeInvoice = async (id) => {
		await axios.delete(`/invoices/${id}`);
	};
	const downloadPdfInvoice = async (id, fileName) => {
		axios
			.get(`/invoices/pdf/${id}`, { responseType: "blob" })
			.then((response) => {
				const href = URL.createObjectURL(response.data);

				const link = document.createElement("a");
				link.href = href;
				link.setAttribute("download", `${fileName}.pdf`); //or any other extension
				document.body.appendChild(link);
				link.click();

				document.body.removeChild(link);
				URL.revokeObjectURL(href);
			});
	};
	return {
		getInvoiceById,
		getAllInvoices,
		getAllInvoicesByMonth,
		getAllUserInvoicesByMonth,
		createInvoice,
		updateInvoice,
		removeInvoice,
		downloadPdfInvoice,
		sendInvoiceMessage,
	};
};
