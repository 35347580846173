import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Modal, Select, Space, Typography } from "antd";
import "./UserEditModal.scss";
import { useRef, useState } from "react";
import { SELECT_WORKING_HOURS } from "../../common/constants/workingHours";

function UserEditModal({
	visibleEditUserModal,
	setVisibleEditUserModal,
	editUser,
	userRoles,
	projects,
	selectedProjects,
	handleSubmitEditUser,
	handleChangeProjects,
	handleRemoveProject,
	setSelectedProjects,
	isUserEditLoading,
	setPrevValue,
}) {
	const [changeField, setChangeField] = useState({});
	const [userEditForm] = Form.useForm();

	const formRef = useRef(null);
	const handleUserEditCancel = () => {
		setVisibleEditUserModal(false);
		userEditForm.resetFields();
		setSelectedProjects(projects);
	};

	const handleFieldsChange = (changedFields) => {
		setChangeField(changedFields[0].name);
	};

	return (
		<Modal
			className="edit-user-modal"
			open={visibleEditUserModal}
			onOk={userEditForm.submit}
			onCancel={handleUserEditCancel}
			width={800}
			destroyOnClose={true}
			confirmLoading={isUserEditLoading}
		>
			<Divider style={{ marginTop: 40 }}>
				<Typography.Title level={4} style={{ margin: "auto" }}>
					{`${editUser?.firstName} ${editUser?.lastName}`}
				</Typography.Title>
			</Divider>
			<Form
				ref={formRef}
				form={userEditForm}
				style={{ paddingTop: 10 }}
				onFinish={handleSubmitEditUser}
				preserve={false}
				onFieldsChange={handleFieldsChange}
			>
				<Form.Item style={{ marginBottom: 5 }}>
					<Form.Item
						label="Role"
						name="roleIds"
						style={{
							display: "inline-block",
							width: "100%",
						}}
						initialValue={editUser?.roleIds}
					>
						<Select
							mode="multiple"
							allowClear
							style={{ width: "100%" }}
							placeholder="Select user roles"
							options={userRoles}
						/>
					</Form.Item>
				</Form.Item>
				<Form.Item style={{ marginBottom: 5 }}>
					<Form.Item
						label="Project"
						name="project"
						style={{
							display: "inline-block",
							width: "100%",
						}}
					>
						<Form.List
							name="projectIds"
							initialValue={editUser?.userProjects?.map(
								(userProject) => ({
									projectId: userProject.projectId,
									workType: userProject.workType,
									dayWorkingHours:
										userProject.dayWorkingHours,
								})
							)}
						>
							{(fields, { add, remove }) => (
								<>
									{fields.map(
										({ key, name, ...restField }) => {
											return (
												<Space
													key={key}
													style={{
														alignItems: "baseline",
														width: "100%",
													}}
												>
													<Form.Item
														{...restField}
														name={[
															name,
															"projectId",
														]}
														rules={[
															{
																required: true,
																message: `Project #${
																	key + 1
																} is required`,
															},
														]}
														style={{
															width: "200px",
														}}
													>
														<Select
															style={{
																width: "100%",
															}}
															placeholder="Select user project"
															onChange={(
																value,
																label
															) => {
																handleChangeProjects(
																	value,
																	label
																);
															}}
															onClick={() => {
																setPrevValue(
																	formRef.current.getFieldValue(
																		"projectIds"
																	)[name]
																		?.projectId
																);
															}}
															options={
																selectedProjects
															}
														/>
													</Form.Item>
													<Form.Item
														{...restField}
														name={[
															name,
															"workType",
														]}
														rules={[
															{
																required: true,
																message: `Work type #${
																	key + 1
																} is required`,
															},
														]}
														style={{
															width: "200px",
														}}
													>
														<Select
															allowClear
															style={{
																width: "100%",
															}}
															placeholder="Select user work type"
															options={[
																{
																	value: "full-time",
																	label: "Full-time",
																},
																{
																	value: "part-time",
																	label: "Part-time",
																},
															]}
														/>
													</Form.Item>
													<Form.Item
														{...restField}
														name={[
															name,
															"dayWorkingHours",
														]}
														style={{
															width: "150px",
														}}
														hidden={
															"part-time" ===
																userEditForm.getFieldValue(
																	[
																		"projectIds",
																		name,
																		"workType",
																	]
																) ||
															changeField ===
																[
																	"projectIds",
																	name,
																	"workType",
																]
														}
													>
														<Select
															allowClear
															style={{
																width: "100%",
															}}
															placeholder="Working hours"
															options={
																SELECT_WORKING_HOURS
															}
														/>
													</Form.Item>
													<MinusCircleOutlined
														onClick={() => {
															handleRemoveProject(
																formRef.current.getFieldValue(
																	"projectIds"
																)[name]
																	?.projectId
															);
															remove(name);
														}}
													/>
												</Space>
											);
										}
									)}
									{projects?.length !== fields?.length && (
										<Form.Item
											style={{
												display: "inline-block",
											}}
										>
											<Button
												type="dashed"
												onClick={() => add()}
												icon={<PlusOutlined />}
											>
												Add field
											</Button>
										</Form.Item>
									)}
								</>
							)}
						</Form.List>
					</Form.Item>
				</Form.Item>
			</Form>
		</Modal>
	);
}

export default UserEditModal;
