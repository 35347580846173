import { Divider, Form, Input, Modal, Select, Typography } from "antd";

function ProjectModal({
	projectName,
	country,
	location,
	type,
	visibleProjectModal,
	setVisibleAddProjectModal,
	handleSaveProject,
	setEditProject,
	isModalLoading,
}) {
	const [projectForm] = Form.useForm();

	const handleProjectAddCancel = () => {
		setEditProject({});
		projectForm.resetFields();
		setVisibleAddProjectModal(false);
	};

	const handleSubmitProject = async (values) => {
		try {
			await handleSaveProject(values);
			projectForm.resetFields();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Modal
			className="add-user-modal"
			open={visibleProjectModal}
			onOk={projectForm.submit}
			onCancel={handleProjectAddCancel}
			width={600}
			confirmLoading={isModalLoading}
			destroyOnClose={true}
		>
			<Divider style={{ marginTop: 40 }}>
				<Typography.Title level={4} style={{ margin: "auto" }}>
					{projectName || "Project Information"}
				</Typography.Title>
			</Divider>
			<Form
				form={projectForm}
				style={{ paddingTop: 10 }}
				onFinish={handleSubmitProject}
				initialValues={{ name: projectName, country, location, type }}
			>
				<Form.Item style={{ marginBottom: 5 }}>
					<Form.Item
						name="name"
						rules={[{ required: true }]}
						style={{
							display: "inline-block",
							width: "100%",
						}}
					>
						<Input
							defaultValue={projectName}
							size="large"
							placeholder="Name"
						/>
					</Form.Item>
				</Form.Item>
				<Form.Item style={{ marginBottom: 5 }}>
					<Form.Item
						name="country"
						rules={[{ required: true }]}
						style={{
							display: "inline-block",
							width: "100%",
						}}
					>
						<Input
							defaultValue={country}
							value={country}
							size="large"
							placeholder="Country"
						/>
					</Form.Item>
				</Form.Item>
				<Form.Item style={{ marginBottom: 5 }}>
					<Form.Item
						name="location"
						rules={[{ required: true }]}
						style={{
							display: "inline-block",
							width: "100%",
						}}
					>
						<Input
							defaultValue={location}
							value={location}
							size="large"
							placeholder="Location"
						/>
					</Form.Item>
				</Form.Item>
				<Form.Item style={{ marginBottom: 5 }}>
					<Form.Item
						name="type"
						rules={[{ required: true }]}
						style={{
							display: "inline-block",
							width: "100%",
						}}
					>
						<Select
							size="large"
							allowClear
							style={{ width: "100%" }}
							placeholder="Select project type"
							options={[
								{
									value: "fixed-price",
									label: "Fixed Price",
								},
								{
									value: "hourly-rate",
									label: "Hourly Rate",
								},
							]}
							defaultValue={type}
							value={type}
						/>
					</Form.Item>
				</Form.Item>
			</Form>
		</Modal>
	);
}

export default ProjectModal;
