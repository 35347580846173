import {Badge, List, Popover} from "antd";
import {BellOutlined} from "@ant-design/icons";
import {useState} from "react";

function NotificationIconBadge() {
    const [open, setOpen] = useState(false);

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const notifications = [
        'Notification text here 1',
        'Notification text here 2',
        'Notification text here 3',
        'Notification text here 4',
    ];

    return (
        <Popover
            placement="bottom"
            content={
                <List
                    size="large"
                    dataSource={notifications}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                />
            }
            title="Notifications"
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
        >
            <Badge count={7}>
                <BellOutlined className={'bell-icon'} />
            </Badge>
        </Popover>
    );
}

export default NotificationIconBadge;
