import { useAxios } from "./axiosService";

export const useProjectService = () => {
	const { axios } = useAxios();

	const getProjectById = async (id) => {
		return await axios.get(`/projects/${id}`);
	};
	const getAllProjects = async () => {
		return await axios.get("/projects");
	};
	const createProject = async (body) => {
		return await axios.post("/projects", body);
	};
	const updateProject = async (id, body) => {
		await axios.put(`/projects/${id}`, body);
	};
	const removeProject = async (id) => {
		await axios.delete(`/projects/${id}`);
	};
	return {
		getProjectById,
		getAllProjects,
		createProject,
		updateProject,
		removeProject,
	};
};
