import {Card, Row, Typography} from "antd";
import './SignIn.scss';
import SignInButton from '../../components/auth/SignInButton';

const {Text} = Typography;

function SignIn() {

    return (
        <div className="sign-in">
            <Card title="Sign in to Elara ERP" bordered={false} style={{width: 400}}>
                <Row justify="center" align="center">
                    <SignInButton/>
                </Row>
                <div className="mt-2">
                    <Text type="secondary">By Intercode, 2022</Text>
                </div>
            </Card>
        </div>
    );
}

export default SignIn;
