import { useAxios } from "./axiosService";

export const useHourRateService = () => {
	const { axios } = useAxios();

	const createHourRate = async (body) => {
		return await axios.post("/hour-rate", body);
	};
	const updateHourRate = async (id, body) => {
		await axios.put(`/hour-rate/${id}`, body);
	};
	const removeHourRate = async (id) => {
		await axios.delete(`/hour-rate/${id}`);
	};
	return {
		createHourRate,
		updateHourRate,
		removeHourRate,
	};
};
