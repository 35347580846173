import "./App.scss";

import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb, Layout } from "antd";

import logo from "./assets/logo_erp.png";
import NotificationIconBadge from "./components/header/NotificationIconBadge";
import UserIconDropdown from "./common/components/UserIconDropdown";
import LeftMenu from "./common/components/LeftMenu";
import { AuthContext } from "./context/AuthContext";
import AppLoader from "./common/components/AppLoader";

const { Header, Content, Sider } = Layout;

function App() {
	const { fetchUser, isUserLoading } = useContext(AuthContext);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		fetchUser();
	}, [fetchUser]);

	useEffect(() => {
		if (location.pathname === "/") {
			navigate("/compensation");
		}
	}, [location, navigate]);

	return !isUserLoading ? (
		<Layout className="main-wrapper">
			<Header className="header">
				<div className="header__inner">
					<Link className="header__logo" to="/">
						<img alt={"Intercode logo"} height="30px" src={logo} />
					</Link>
					<div className="notifications-user">
						{/* <span className="mr-3">
							<NotificationIconBadge />
						</span> */}

						<UserIconDropdown />
					</div>
				</div>
			</Header>
			<Layout>
				<Sider width={200} className="site-layout-background">
					<LeftMenu />
				</Sider>
				<Layout style={{ padding: "24px 24px", overflow: "scroll" }}>
					{/* <Breadcrumb style={{ margin: "16px 0" }}>
						<Breadcrumb.Item>Home</Breadcrumb.Item>
						<Breadcrumb.Item>People</Breadcrumb.Item>
						<Breadcrumb.Item>List</Breadcrumb.Item>
					</Breadcrumb> */}
					<Content className="site-layout-background">
						<Outlet />
					</Content>
				</Layout>
			</Layout>
		</Layout>
	) : (
		<AppLoader />
	);
}

export default App;
