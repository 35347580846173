/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Table,
	Tag,
	Popconfirm,
	Modal,
	Divider,
	Typography,
	Avatar,
	Badge,
} from "antd";
import { DeleteOutlined, EditOutlined, UserOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import "./Projects.scss";
import { useProjectService } from "../../services/projectService";
import ProjectModal from "../../components/projects/ProjectModal";

function Projects() {
	const projectService = useProjectService();

	const [projects, setProjects] = useState();
	const [isProjectsLoading, setIsProjectsLoading] = useState(false);

	const [editProject, setEditProject] = useState({});
	const [isModalLoading, setIsModalLoading] = useState(false);

	const [projectInfo, setProjectInfo] = useState({});

	const [visibleProjectModal, setVisibleProjectModal] = useState(false);
	const [visibleUsersModal, setVisibleUsersModal] = useState(false);

	useEffect(() => {
		fetchProjects();
	}, []);

	const fetchProjects = useCallback(async () => {
		try {
			setIsProjectsLoading(true);
			const { data } = await projectService.getAllProjects();

			setProjects(
				data.map((project) => ({ ...project, key: project.id }))
			);
		} catch (e) {
			console.error(e);
		} finally {
			setIsProjectsLoading(false);
		}
	}, []);

	const confirmDelete = async (project) => {
		await projectService.removeProject(project.id);
		await fetchProjects();
	};

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			// specify the condition of filtering result
			// here is that finding the name started with `value`
			onFilter: (value, record) => record.name.indexOf(value) === 0,
			sorter: (a, b) => a.name.length - b.name.length,
			sortDirections: ["descend"],
			key: "name",
		},
		{
			title: "Country",
			dataIndex: "country",
			key: "country",
		},
		{
			title: "Location",
			dataIndex: "location",
			key: "location",
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			render: (_, { type }) => (
				<Tag color={"blue"} key={type}>
					{type.toUpperCase()}
				</Tag>
			),
		},
		{
			title: "Actions",
			dataIndex: "actions",
			key: "actions",
			width: "15%",
			render: (text, record) => (
				<div>
					<Badge
						count={record.userProjects.length}
						className="mr-1"
						color="#4d7df7"
					>
						<Button
							icon={<UserOutlined />}
							onClick={() => {
								setVisibleUsersModal(true);
								setProjectInfo(record);
							}}
						/>
					</Badge>
					<Button
						onClick={() => showModal(true, record)}
						icon={<EditOutlined />}
					/>
					<Popconfirm
						title="Delete the project?"
						description="Are you sure to delete this project?"
						onConfirm={() => confirmDelete(record)}
						okText="Yes"
						cancelText="No"
					>
						<Button
							danger
							icon={<DeleteOutlined />}
							className="ml-1"
						/>
					</Popconfirm>
				</div>
			),
		},
	];

	const userTableColumns = [
		{
			title: "",
			dataIndex: "photo",
			key: "photo",
			width: "50px",
			render: (photo) => <Avatar size="large" src={`${photo}`} />,
		},
		{
			title: "Name",
			dataIndex: "name",
			// specify the condition of filtering result
			// here is that finding the name started with `value`
			onFilter: (value, record) => record.name.indexOf(value) === 0,
			sorter: (a, b) => a.name.length - b.name.length,
			sortDirections: ["descend"],
			key: "name",
			width: "250px",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			width: "300px",
		},
		{
			title: "Work type",
			dataIndex: "workType",
			key: "workType",
			width: "300px",
		},
	];

	const onChange = (pagination, filters, sorter, extra) => {};

	const showModal = async (isEdit, info) => {
		if (!isEdit) {
			setEditProject({});
		} else {
			await fetchProjects();
			setEditProject(info);
		}
		setVisibleProjectModal(true);
	};

	const handleSubmitProject = async (values) => {
		try {
			setIsModalLoading(true);

			if (Object.keys(editProject).length) {
				await projectService.updateProject(editProject.id, values);
			} else {
				await projectService.createProject(values);
			}
			setIsModalLoading(false);
			setVisibleProjectModal(false);

			await fetchProjects();
		} catch (error) {
			console.log(error);
		}
	};

	const userTableSource = projectInfo?.userProjects?.map((userProject) => ({
		...userProject.user,
		name: `${userProject.user.firstName} ${userProject.user.lastName}`,
		workType: userProject.workType,
		key: userProject.user.id,
	}));

	return (
		<>
			<div className="buttons-section">
				<div>
					<Button type="primary" onClick={() => showModal()}>
						Add new project
					</Button>
					{visibleProjectModal && (
						<ProjectModal
							projectName={editProject.name}
							country={editProject.country}
							location={editProject.location}
							type={editProject.type}
							visibleProjectModal={visibleProjectModal}
							setVisibleAddProjectModal={setVisibleProjectModal}
							handleSaveProject={handleSubmitProject}
							setEditProject={setEditProject}
							isModalLoading={isModalLoading}
						/>
					)}
					<Modal
						className="add-user-modal"
						open={visibleUsersModal}
						width={800}
						confirmLoading={isModalLoading}
						destroyOnClose={true}
						onCancel={() => setVisibleUsersModal(false)}
						footer={null}
					>
						<Divider style={{ marginTop: 40 }}>
							<Typography.Title
								level={4}
								style={{ margin: "auto" }}
							>
								{`${projectInfo?.name}`}
							</Typography.Title>
						</Divider>
						<Table
							columns={userTableColumns}
							dataSource={userTableSource}
						/>
					</Modal>
				</div>
			</div>
			<Table
				columns={columns}
				dataSource={projects}
				onChange={onChange}
				loading={isProjectsLoading}
			/>
		</>
	);
}

export default Projects;
