import { Input } from "antd";
import React, { FC } from "react";
// import TextareaAutosize from 'react-textarea-autosize'
// import { Text } from '@react-pdf/renderer'
// import compose from '../styles/compose'

const EditableTextarea = ({
	style,
	placeholder,
	value,
	onChange,
	pdfMode,
	rows,
}) => {
	return (
		<Input.TextArea
			rows={rows}
			value={value}
			placeholder={placeholder}
			onChange={onChange}
			style={{
				...style,
				borderColor: "transparent",
				backgroundColor: "transparent",
			}}
		/>
	);
};

export default EditableTextarea;
