export const initialProductLine = {
	description: "",
	quantity: "1",
	rate: "0",
};

export const initialInvoice = {
	logo: "",
	logoWidth: 100,
	title: "INVOICE",
	companyName: "LLC Inter Code",
	companySenderName: "Valentyn Stets",
	companyAddress: "",
	companyAddress2: "",
	companyCountry: "Ukraine",
	billTo: "Bill To:",
	clientName: "",
	clientAddress: "",
	clientAddress2: "",
	clientCountry: "Ukraine",
	invoiceTitleLabel: "Invoice#",
	invoiceName: "",
	invoiceDateLabel: "Invoice Date",
	date: "",
	invoiceDueDateLabel: "Due Date",
	invoiceDueDate: "",
	productLineDescription: "Item Description",
	productLineQuantity: "Qty",
	productLineQuantityRate: "Rate",
	productLineQuantityAmount: "Amount",
	productLines: [
		{
			description: "",
			quantity: "1",
			rate: "0",
		},
	],
	subTotalLabel: "Sub Total",
	taxLabel: "Sale Tax (5%)",
	totalLabel: "TOTAL",
	currency: "$",
	notesLabel: "Notes",
	notes: "",
	termLabel: "Terms & Conditions",
	term: "",
};
