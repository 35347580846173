import { useAxios } from "./axiosService";

export const useUserService = () => {
	const { axios } = useAxios();

	const getUser = async () => {
		const user = await axios.get("/auth/me");
		return user;
	};
	const getUserById = async (id) => {
		return await axios.get(`/users/${id}`);
	};
	const getAllUsers = async () => {
		const users = await axios.get("/users");
		return users;
	};
	const importGoogleUsers = async () => {
		const users = await axios.get("/users/import-google-users");
		return users;
	};
	const addGoogleUser = async (body) => {
		const user = await axios.post("/users/add-google-user", { ...body });
		return user;
	};
	const getUserRoles = async () => {
		const roles = await axios.get("/users/user-roles");
		return roles;
	};
	const updateUser = async (body) => {
		await axios.put("/users/update-user", body);
	};
	return {
		getUser,
		getUserById,
		getAllUsers,
		importGoogleUsers,
		addGoogleUser,
		getUserRoles,
		updateUser,
	};
};
