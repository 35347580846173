import React, { useState, useEffect } from "react";
import { initialInvoice, initialProductLine } from "./data/initialData";
import EditableInput from "./EditableInput";
import EditableTextarea from "./EditableTextarea";
import EditableCalendarInput from "./EditableCalendarInput";
import Page from "./Page";
import "./style.scss";
import { Button, Image, Switch } from "antd";
import {
	FilePdfOutlined,
	PlusCircleOutlined,
	SaveOutlined,
	SendOutlined,
} from "@ant-design/icons";
import logo from "../../assets/logo_intercode.png";
import dayjs from "dayjs";

import customParseFormat from "dayjs/plugin/customParseFormat";

import advancedFormat from "dayjs/plugin/advancedFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import { useAuth } from "../../context/AuthContext";
import { ADMIN_ROLES } from "../../common/constants/adminRoles";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const dateFormat = "DD-MM-YYYY";

// TODO: read-only mod for non-admin users

const InvoicePage = ({
	data,
	pdfMode,
	onChange,
	saveInvoice,
	isNewInvoice,
	downloadInvoice,
	sendInvoiceMessage,
	isInvoiceSendMessageLoading,
}) => {
	const { currentUser } = useAuth();

	const [invoice, setInvoice] = useState(
		data
			? {
					...data,
					taxLabel: data.taxPercentage
						? `Sale Tax (${data.taxPercentage}%)`
						: initialInvoice.taxLabel,
			  }
			: { ...initialInvoice }
	);
	const [subTotal, setSubTotal] = useState(data.total);
	const [saleTax, setSaleTax] = useState(data.tax);
	const [isSaleTaxShow, setIsSaleTaxShow] = useState(!!data.taxPercentage);

	const [invoiceDate, setInvoiceDate] = useState(
		invoice.date !== "" ? dayjs(invoice.date) : dayjs()
	);

	const invoiceDueDate =
		invoice.invoiceDueDate !== ""
			? new Date(invoice.invoiceDueDate)
			: new Date(invoiceDate.valueOf());

	if (invoice.invoiceDueDate === "") {
		invoiceDueDate.setDate(invoiceDueDate.getDate() + 30);
	}

	const handleChange = (name, value) => {
		if (name !== "productLines") {
			const newInvoice = { ...invoice };

			if (name === "logoWidth" && typeof value === "number") {
				newInvoice[name] = value;
			} else if (name !== "logoWidth" && typeof value === "string") {
				newInvoice[name] = value;
			}

			setInvoice(newInvoice);
		}
	};

	const handleProductLineChange = (index, name, value) => {
		const productLines = invoice.productLines.map((productLine, i) => {
			if (i === index) {
				const newProductLine = { ...productLine };

				if (name === "description") {
					newProductLine[name] = value;
				} else {
					if (
						value[value.length - 1] === "." ||
						(value[value.length - 1] === "0" && value.includes("."))
					) {
						newProductLine[name] = value;
					} else {
						const n = parseFloat(value);

						newProductLine[name] = (n ? n : 0).toString();
					}
				}

				return newProductLine;
			}

			return { ...productLine };
		});

		setInvoice({ ...invoice, productLines });
	};

	const handleSaleTaxChange = (value) => {
		setIsSaleTaxShow(value);
	};

	const handleRemove = (i) => {
		const productLines = invoice.productLines.filter(
			(productLine, index) => index !== i
		);

		setInvoice({ ...invoice, productLines });
	};

	const handleAdd = () => {
		const productLines = [
			...invoice.productLines,
			{ ...initialProductLine },
		];

		setInvoice({ ...invoice, productLines });
	};

	const calculateAmount = (quantity, rate) => {
		const quantityNumber = parseFloat(quantity);
		const rateNumber = parseFloat(rate);
		const amount =
			quantityNumber && rateNumber
				? Math.round(quantityNumber * rateNumber)
				: 0;

		return amount?.toFixed(2);
	};

	useEffect(() => {
		let subTotal = 0;

		invoice?.productLines?.forEach((productLine) => {
			const quantityNumber = parseFloat(productLine.quantity);
			const rateNumber = parseFloat(productLine.rate);
			const amount =
				quantityNumber && rateNumber
					? Math.round(quantityNumber * rateNumber)
					: 0;

			subTotal += amount;
		});

		setSubTotal(subTotal);
	}, [invoice.productLines]);

	useEffect(() => {
		const match = invoice.taxLabel?.match(/(\d+)%/);
		const taxRate = match ? parseFloat(match[1]) : 0;
		const saleTax = subTotal ? (subTotal * taxRate) / 100 : 0;

		setSaleTax(isSaleTaxShow ? saleTax : 0);
	}, [subTotal, invoice.taxLabel, isSaleTaxShow]);

	useEffect(() => {
		if (onChange) {
			onChange(invoice);
		}
	}, [onChange, invoice]);

	useEffect(() => {
		setInvoice({
			...data,
			taxLabel: data.taxPercentage
				? `Sale Tax (${data.taxPercentage}%)`
				: invoice.taxLabel,
		});
		setInvoiceDate(data.date !== "" ? dayjs(data.date) : dayjs());
		setSaleTax(data.tax);
		setIsSaleTaxShow(!!data.taxPercentage);
	}, [data]);

	const onClickSave = () => {
		const match = invoice?.taxLabel?.match(/(\d+)%/);
		const taxRate = match ? parseFloat(match[1]) : 0;
		saveInvoice({
			...invoice,
			taxPercentage: isSaleTaxShow ? taxRate : 0,
			date: invoiceDate,
		});
	};

	return (
		// <Document pdfMode={pdfMode}>
		<div>
			<Page className="invoice-wrapper" pdfMode={pdfMode}>
				{/* {!pdfMode && <Download data={invoice} />} */}

				<div style={{ display: "flex" }} pdfMode={pdfMode}>
					<div style={{ width: "50%" }} pdfMode={pdfMode}>
						<Image src={logo} height={100} />
						{/* <EditableFileImage
						className="logo"
						placeholder="Your Logo"
						value={invoice.logo}
						width={invoice.logoWidth}
						pdfMode={pdfMode}
						onChangeImage={(value) => handleChange("logo", value)}
						onChangeWidth={(value) =>
							handleChange("logoWidth", value)
						}
					/> */}
						<EditableInput
							placeholder="Your Company"
							style={{
								fontSize: "20px",
								fontWeight: 600,
								padding: "4px 0",
							}}
							value={invoice.companyName}
							onChange={(value) =>
								handleChange("companyName", value)
							}
							pdfMode={pdfMode}
						/>
						<EditableInput
							style={{ padding: "4px 0" }}
							placeholder="Your Name"
							value={invoice.companySenderName}
							onChange={(value) =>
								handleChange("companySenderName", value)
							}
							pdfMode={pdfMode}
						/>
						<EditableInput
							style={{ padding: "4px 0" }}
							placeholder="Company's Address"
							value={invoice.companyAddress}
							onChange={(value) =>
								handleChange("companyAddress", value)
							}
							pdfMode={pdfMode}
						/>
						<EditableInput
							style={{ padding: "4px 0" }}
							placeholder="City, State Zip"
							value={invoice.companyAddress2}
							onChange={(value) =>
								handleChange("companyAddress2", value)
							}
							pdfMode={pdfMode}
						/>

						<EditableInput
							style={{ padding: "4px 0" }}
							placeholder="Country"
							value={invoice.companyCountry}
							onChange={(value) =>
								handleChange("companyCountry", value)
							}
							pdfMode={pdfMode}
						/>
					</div>
					<div style={{ width: "50%" }} pdfMode={pdfMode}>
						<EditableInput
							style={{
								fontSize: "45px",
								textAlign: "right",
								fontWeight: 600,
							}}
							placeholder="Invoice"
							value={invoice.title}
							onChange={(value) => handleChange("title", value)}
							pdfMode={pdfMode}
						/>
					</div>
				</div>

				<div
					style={{ display: "flex", marginTop: 40 }}
					pdfMode={pdfMode}
				>
					<div style={{ width: "55%" }} pdfMode={pdfMode}>
						{/* <EditableInput
							style={{ padding: "4px 0" }}
							value={invoice.billTo}
							onChange={(value) => handleChange("billTo", value)}
							pdfMode={pdfMode}
						/> */}
						<div style={{ paddingLeft: 1, marginBottom: 5 }}>
							Bill to:
						</div>
						<EditableInput
							style={{ padding: "4px 0", fontWeight: 600 }}
							placeholder="Your Client's Name"
							value={invoice.clientName}
							onChange={(value) =>
								handleChange("clientName", value)
							}
							pdfMode={pdfMode}
						/>
						<EditableInput
							style={{ padding: "4px 0" }}
							placeholder="Client's Address"
							value={invoice.clientAddress}
							onChange={(value) =>
								handleChange("clientAddress", value)
							}
							pdfMode={pdfMode}
						/>
						<EditableInput
							style={{ padding: "4px 0" }}
							placeholder="City, State Zip"
							value={invoice.clientAddress2}
							onChange={(value) =>
								handleChange("clientAddress2", value)
							}
							pdfMode={pdfMode}
						/>
						<EditableInput
							style={{ padding: "4px 0" }}
							placeholder="Country"
							value={invoice.clientCountry}
							onChange={(value) =>
								handleChange("clientCountry", value)
							}
							pdfMode={pdfMode}
						/>
					</div>
					<div style={{ width: "45%" }} pdfMode={pdfMode}>
						<div
							style={{
								display: "flex",
								marginBottom: 5,
								alignItems: "center",
							}}
							pdfMode={pdfMode}
						>
							<div
								style={{
									width: "40%",
									display: "flex",
									alignItems: "center",
								}}
								pdfMode={pdfMode}
							>
								{/* <EditableInput
									style={{
										fontWeight: 600,
										padding: "4px 0",
									}}
									value={invoice.invoiceTitleLabel}
									onChange={(value) =>
										handleChange("invoiceTitleLabel", value)
									}
									pdfMode={pdfMode}
								/> */}
								<div
									style={{
										fontWeight: 600,
										padding: "4px 0",
									}}
								>
									Invoice #
								</div>
							</div>
							<div style={{ width: "60%" }} pdfMode={pdfMode}>
								{invoice.invoiceName !== ""
									? invoice.invoiceName
									: `${dayjs(invoiceDate)
											.format(dateFormat)
											.split("-")
											.join("")}-####`}
								{/* <EditableInput
									style={{ padding: "4px 0" }}
									placeholder="INV-12"
									disabled
									value={invoice.invoiceName}
									onChange={(value) =>
										handleChange("invoiceName", value)
									}
									pdfMode={pdfMode}
								/> */}
							</div>
						</div>
						<div
							style={{ display: "flex", marginBottom: 5 }}
							pdfMode={pdfMode}
						>
							<div
								style={{
									width: "40%",
									display: "flex",
									alignItems: "center",
								}}
								pdfMode={pdfMode}
							>
								{/* <EditableInput
									style={{ padding: "4px 0" }}
									className="bold"
									value={invoice.invoiceDateLabel}
									onChange={(value) =>
										handleChange("invoiceDateLabel", value)
									}
									pdfMode={pdfMode}
								/> */}
								<div
									style={{
										fontWeight: 600,
										padding: "4px 0",
									}}
								>
									Invoice Date
								</div>
							</div>
							<div style={{ width: "60%" }} pdfMode={pdfMode}>
								<EditableCalendarInput
									value={invoiceDate}
									style={{ padding: "4px 0" }}
									onChange={(date) => {
										handleChange(
											"invoiceDate",
											dayjs(date)
										);
									}}
									dateFormat={dateFormat}
									pdfMode={pdfMode}
								/>
							</div>
						</div>
					</div>
				</div>

				<div
					style={{
						marginTop: 30,
						display: "flex",
						backgroundColor: "#555",
					}}
					pdfMode={pdfMode}
				>
					<div
						style={{ width: "48%", padding: "4px 8px" }}
						pdfMode={pdfMode}
					>
						<EditableInput
							style={{ color: "white", fontWeight: 600 }}
							value={
								invoice.productLineDescription ??
								initialInvoice.productLineDescription
							}
							onChange={(value) =>
								handleChange("productLineDescription", value)
							}
							pdfMode={pdfMode}
						/>
					</div>
					<div
						style={{ width: "17%", padding: "4px 8px" }}
						pdfMode={pdfMode}
					>
						<EditableInput
							style={{
								color: "white",
								fontWeight: 600,
								textAlign: "right",
							}}
							value={
								invoice.productLineQuantity ??
								initialInvoice.productLineQuantity
							}
							onChange={(value) =>
								handleChange("productLineQuantity", value)
							}
							pdfMode={pdfMode}
						/>
					</div>
					<div
						style={{ width: "17%", padding: "4px 8px" }}
						pdfMode={pdfMode}
					>
						<EditableInput
							style={{
								color: "white",
								fontWeight: 600,
								textAlign: "right",
							}}
							value={
								invoice.productLineQuantityRate ??
								initialInvoice.productLineQuantityRate
							}
							onChange={(value) =>
								handleChange("productLineQuantityRate", value)
							}
							pdfMode={pdfMode}
						/>
					</div>
					<div
						style={{ width: "17%", padding: "4px 8px" }}
						pdfMode={pdfMode}
					>
						<EditableInput
							style={{
								color: "white",
								fontWeight: 600,
								textAlign: "right",
							}}
							value={
								invoice.productLineQuantityAmount ??
								initialInvoice.productLineQuantityAmount
							}
							onChange={(value) =>
								handleChange("productLineQuantityAmount", value)
							}
							pdfMode={pdfMode}
						/>
					</div>
				</div>

				{invoice?.productLines?.map((productLine, i) => {
					return pdfMode && productLine.description === "" ? (
						<div key={i}></div>
					) : (
						<div
							key={i}
							style={{
								display: "flex",
								borderBottom: "1px solid #e3e3e3",
							}}
							className="row"
							pdfMode={pdfMode}
						>
							<div
								style={{
									width: "48%",
									padding: "4px 8px",
									paddingBottom: "10px",
								}}
								pdfMode={pdfMode}
							>
								<EditableTextarea
									rows={1}
									placeholder="Enter item name/description"
									value={productLine.description}
									onChange={({ target }) => {
										handleProductLineChange(
											i,
											"description",
											target.value
										);
									}}
									pdfMode={pdfMode}
								/>
							</div>
							<div
								style={{
									width: "17%",
									padding: "4px 8px",
									paddingBottom: "10px",
								}}
								pdfMode={pdfMode}
							>
								<EditableInput
									style={{ textAlign: "right" }}
									className="dark right"
									value={productLine.quantity}
									onChange={(value) =>
										handleProductLineChange(
											i,
											"quantity",
											value
										)
									}
									pdfMode={pdfMode}
								/>
							</div>
							<div
								style={{
									width: "17%",
									padding: "4px 8px",
									paddingBottom: "10px",
								}}
								pdfMode={pdfMode}
							>
								<EditableInput
									style={{ textAlign: "right" }}
									className="dark right"
									value={productLine.rate}
									onChange={(value) =>
										handleProductLineChange(
											i,
											"rate",
											value
										)
									}
									pdfMode={pdfMode}
								/>
							</div>
							<div
								style={{
									width: "18%",
									padding: "4px 8px",
									paddingBottom: "10px",
								}}
								pdfMode={pdfMode}
							>
								<div
									style={{
										textAlign: "right",
										padding: "4px 11px",
										lineHeight: 1.5714285714285714,
									}}
									pdfMode={pdfMode}
								>
									{calculateAmount(
										productLine.quantity,
										productLine.rate
									)}
								</div>
							</div>
							{!pdfMode && (
								<Button
									className="link row__remove"
									aria-label="Remove Row"
									title="Remove Row"
									onClick={() => handleRemove(i)}
								>
									<span className="icon icon-remove bg-red"></span>
								</Button>
							)}
						</div>
					);
				})}

				<div style={{ display: "flex" }} pdfMode={pdfMode}>
					<div
						style={{
							width: "50%",
							marginTop: 10,
						}}
						pdfMode={pdfMode}
					>
						{!pdfMode && (
							<Button
								type="dashed"
								icon={
									<PlusCircleOutlined
										style={{ color: "green", fontSize: 15 }}
									/>
								}
								onClick={handleAdd}
							>
								Add Line Item
							</Button>
						)}
						{!pdfMode && (
							<div style={{ display: "block", marginTop: 15 }}>
								<div
									style={{
										fontSize: 15,
										fontWeight: 600,
										marginRight: 20,
										display: "inline-block",
									}}
								>
									Sale Tax
								</div>
								<Switch
									checked={isSaleTaxShow}
									onChange={handleSaleTaxChange}
								/>
							</div>
						)}
					</div>

					<div
						style={{ width: "50%", marginTop: 20 }}
						pdfMode={pdfMode}
					>
						<div
							style={{ display: "flex", alignItems: "center" }}
							pdfMode={pdfMode}
						>
							<div
								style={{ width: "50%", padding: 5 }}
								pdfMode={pdfMode}
							>
								<EditableInput
									value={
										invoice.subTotalLabel ??
										initialInvoice.subTotalLabel
									}
									onChange={(value) =>
										handleChange("subTotalLabel", value)
									}
									pdfMode={pdfMode}
								/>
							</div>
							<div
								style={{
									textAlign: "right",
									fontWeight: 600,
									width: "50%",
									padding: 5,
								}}
								pdfMode={pdfMode}
							>
								{subTotal?.toFixed(2)}
							</div>
						</div>
						{isSaleTaxShow && (
							<div
								style={{
									display: "flex",
									alignItems: "center",
								}}
								pdfMode={pdfMode}
							>
								<div
									style={{ width: "50%", padding: 5 }}
									pdfMode={pdfMode}
								>
									<EditableInput
										value={
											invoice.taxLabel ??
											initialInvoice.taxLabel
										}
										onChange={(value) =>
											handleChange("taxLabel", value)
										}
										pdfMode={pdfMode}
									/>
								</div>
								<div
									style={{
										textAlign: "right",
										fontWeight: 600,
										width: "50%",
										padding: 5,
									}}
									pdfMode={pdfMode}
								>
									{+saleTax?.toFixed(2)}
								</div>
							</div>
						)}
						<div
							style={{
								display: "flex",
								padding: 5,
								backgroundColor: "#e3e3e3",
								alignItems: "center",
							}}
							pdfMode={pdfMode}
						>
							<div
								style={{ width: "50%", padding: 5 }}
								pdfMode={pdfMode}
							>
								<EditableInput
									style={{ fontWeight: 600 }}
									value={
										invoice.totalLabel ??
										initialInvoice.totalLabel
									}
									onChange={(value) =>
										handleChange("totalLabel", value)
									}
									pdfMode={pdfMode}
								/>
							</div>
							<div
								style={{
									padding: 5,
									display: "flex",
									alignItems: "center",
								}}
								pdfMode={pdfMode}
							>
								<EditableInput
									style={{
										fontWeight: 600,
										marginLeft: 30,
										textAlign: "right",
									}}
									value={
										invoice.currency ??
										initialInvoice.currency
									}
									onChange={(value) =>
										handleChange("currency", value)
									}
									pdfMode={pdfMode}
								/>
								<div
									style={{
										fontWeight: 600,
										textAlign: "right",
										width: "auto",
									}}
									pdfMode={pdfMode}
								>
									{(typeof subTotal !== "undefined" &&
									typeof saleTax !== "undefined"
										? subTotal + saleTax
										: 0
									)?.toFixed(2)}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div style={{ marginTop: 20 }} pdfMode={pdfMode}>
					<div
						style={{
							fontWeight: 600,
							padding: "4px 11px",
							color: "#d9d9d9",
						}}
					>
						Notes
					</div>
					<EditableTextarea
						style={{ width: "100%" }}
						rows={2}
						placeholder="It was great doing business with you."
						value={invoice.notes}
						onChange={({ target }) => {
							handleChange("notes", target.value);
						}}
						pdfMode={pdfMode}
					/>
				</div>
				<div style={{ marginTop: 20 }} pdfMode={pdfMode}>
					<div
						style={{
							fontWeight: 600,
							padding: "4px 11px",
							color: "#d9d9d9",
						}}
					>
						Terms & Conditions
					</div>
					<EditableTextarea
						style={{ width: "100%" }}
						rows={2}
						placeholder="Please make the payment by the due date."
						value={invoice.term}
						onChange={({ target }) => {
							handleChange("term", target.value);
						}}
						pdfMode={pdfMode}
					/>
				</div>
			</Page>
			<div
				style={{
					display: "flex",
					justifyContent: "space-around",
					marginTop: 30,
				}}
			>
				<Button
					style={{ fontWeight: 600, width: 160 }}
					type="primary"
					icon={<FilePdfOutlined />}
					onClick={() => {
						downloadInvoice(invoice.id, invoice.invoiceName);
					}}
				>
					Save PDF
				</Button>
				{currentUser?.userRoleNames?.some((role) =>
					ADMIN_ROLES.includes(role)
				) && (
					<Button
						style={{ fontWeight: 600, width: 160 }}
						type="primary"
						icon={<SaveOutlined />}
						onClick={onClickSave}
					>
						{isNewInvoice ? "Save" : "Update"}
					</Button>
				)}
				{currentUser?.userRoleNames?.some((role) =>
					ADMIN_ROLES.includes(role)
				) && (
					<Button
						style={{ fontWeight: 600, width: 160 }}
						type="primary"
						icon={<SendOutlined />}
						onClick={() => {
							sendInvoiceMessage(invoice);
						}}
						loading={isInvoiceSendMessageLoading}
					>
						Send
					</Button>
				)}
			</div>
		</div>

		// </Document>
	);
};

export default InvoicePage;
