import { DatePicker } from "antd";
import React from "react";

const EditableCalendarInput = ({ style, onChange, value, dateFormat }) => {
	return (
		<DatePicker
			style={{
				...style,
				borderColor: "transparent",
				backgroundColor: "transparent",
			}}
			onChange={onChange}
			allowClear={false}
			value={value}
			format={dateFormat}
		/>
	);
};

export default EditableCalendarInput;
