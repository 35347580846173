/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Table,
	Tag,
	Modal,
	Divider,
	Typography,
	Avatar,
	Form,
	Space,
	InputNumber,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import "./HourRates.scss";
import { useUserService } from "../../services/userService";
import { useHourRateService } from "../../services/hourRateService";

function HourRates() {
	const userService = useUserService();
	const hourRateService = useHourRateService();

	const [hourRateForm] = Form.useForm();

	const [users, setUsers] = useState([]);
	const [isUsersLoading, setIsUsersLoading] = useState(false);

	const [editHourRate, setEditHourRate] = useState({});
	const [isModalConfirmLoading, setIsModalConfirmLoading] = useState(false);

	const [visibleHourRateModal, setVisibleHourRateModal] = useState(false);

	useEffect(() => {
		fetchUsers();
	}, []);

	const fetchUsers = useCallback(async () => {
		try {
			setIsUsersLoading(true);
			const { data } = await userService.getAllUsers();

			setUsers(
				data.map((user) => ({
					...user,
					name: `${user.firstName} ${user.lastName}`,
					roleNames: user.userRoles.map(
						(userRole) => userRole.role.name
					),
					roleIds: user.userRoles.map((userRole) => userRole.role.id),
					key: user.id,
					projects: user.userProjects.map(
						(userProject) => userProject.project.name
					),
					hourlyRates: user.userHourRates || [],
				}))
			);

			console.log(data);
		} catch (e) {
			console.error(e);
		} finally {
			setIsUsersLoading(false);
		}
	}, []);

	const columns = [
		{
			title: "",
			dataIndex: "photo",
			key: "photo",
			width: "50px",
			render: (photo) => <Avatar size="large" src={`${photo}`} />,
		},
		{
			title: "Name",
			dataIndex: "name",
			onFilter: (value, record) => record.name.indexOf(value) === 0,
			sorter: (a, b) => a.name.length - b.name.length,
			sortDirections: ["descend"],
			key: "name",
		},
		{
			title: "Hourly rate",
			dataIndex: "hourlyRates",
			key: "hourlyRates",
			render: (_, { hourlyRates }) => (
				<div>
					{hourlyRates?.map((hourlyRates, idx) => (
						<div style={{ display: "block" }}>
							<Tag
								color={"blue"}
								style={{
									marginBottom: "5px",
								}}
								key={idx}
							>
								{hourlyRates.project?.name.toUpperCase()}
							</Tag>
							<Tag
								color={"green"}
								style={{
									marginBottom: "5px",
								}}
								key={hourlyRates.rate}
							>
								{hourlyRates.rate} $
							</Tag>
						</div>
					))}
				</div>
			),
		},

		{
			title: "Actions",
			dataIndex: "actions",
			key: "actions",
			width: "15%",
			render: (text, record) => (
				<div>
					<Button
						onClick={() => showModal(record)}
						icon={<EditOutlined />}
					/>
				</div>
			),
		},
	];

	const onChange = (pagination, filters, sorter, extra) => {};

	const showModal = async (info) => {
		setEditHourRate({
			...info,
			userProjects: info.userProjects.map((userProject) => {
				const hourRate =
					info?.userHourRates.find(
						(userHourRate) =>
							userProject.projectId === userHourRate.project?.id
					) ?? 0;
				const projectHours = userProject.monthWorkingHours;

				return {
					...userProject,
					hourRate,
					projectHours,
				};
			}),
		});
		setVisibleHourRateModal(true);
	};

	const handleSubmitEditUser = async (values) => {
		setIsModalConfirmLoading(true);
		let data = [];

		for (const key in values) {
			data.push({
				projectId: +key,
				userId: editHourRate.id,
				rate: values[key].hourRate ?? 0,
				amount: values[key].amount ?? 0,
			});
		}

		await hourRateService.createHourRate(data);

		await fetchUsers();
		setVisibleHourRateModal(false);
		setIsModalConfirmLoading(false);
	};

	const handleFieldsChange = (changedFields) => {
		const names = changedFields.map((field) => field.name[1]);
		if (
			names.includes("amount") ||
			names.includes("projectHours") ||
			names.includes("hourRate")
		) {
			const amount =
				hourRateForm.getFieldValue([
					changedFields[0].name[0],
					"amount",
				]) || 0;
			const projectHours =
				hourRateForm.getFieldValue([
					changedFields[0].name[0],
					"projectHours",
				]) || 0;
			const hourRate =
				hourRateForm.getFieldValue([
					changedFields[0].name[0],
					"hourRate",
				]) || 0;

			let newAmount, newProjectHours, newHourRate;
			if (names.includes("amount")) {
				newAmount = amount;
				newProjectHours = projectHours;
				newHourRate =
					amount && projectHours
						? cutFloat(amount / projectHours)
						: hourRate;
			} else if (names.includes("projectHours")) {
				newAmount = amount;
				newProjectHours = projectHours;
				newHourRate =
					amount && projectHours
						? cutFloat(amount / projectHours)
						: hourRate;
			} else if (names.includes("hourRate")) {
				newAmount = Math.round(projectHours * hourRate);
				newProjectHours = projectHours;
				newHourRate = hourRate;
			}

			hourRateForm.setFieldsValue({
				[changedFields[0].name[0]]: {
					amount: newAmount,
					projectHours: newProjectHours,
					hourRate: newHourRate,
				},
			});
		}
	};

	const cutFloat = (number) => {
		const cutNum = Math.floor(number * 1000) / 1000;
		const cutString = cutNum.toFixed(3);
		return parseFloat(cutString);
	};

	return (
		<>
			<div className="buttons-section">
				<div>
					<Modal
						className="add-user-modal"
						open={visibleHourRateModal}
						width={800}
						confirmLoading={isModalConfirmLoading}
						destroyOnClose={true}
						onOk={hourRateForm.submit}
						onCancel={() => setVisibleHourRateModal(false)}
					>
						<Divider style={{ marginTop: 40 }}>
							<Typography.Title
								level={4}
								style={{ margin: "auto" }}
							>
								{`${editHourRate?.name}`}
							</Typography.Title>
						</Divider>
						<div style={{ display: "flex", marginTop: 30 }}>
							<div
								style={{
									fontWeight: "'bolder",
									fontSize: "16px",
									marginRight: "18px",
								}}
							>
								Projects:
							</div>

							<Form
								form={hourRateForm}
								onFinish={handleSubmitEditUser}
								onFieldsChange={handleFieldsChange}
							>
								<Form.Item style={{ marginBottom: 0 }}>
									{editHourRate?.userProjects?.map(
										(userProject, idx) => {
											return (
												<Space
													key={idx}
													style={{
														width: "100%",
														marginBottom: "10px",
													}}
												>
													<Tag
														style={{
															fontSize: "15px",
															padding: "5px 10px",
															width: "130px",
															textAlign: "center",
														}}
														color="blue"
													>
														{
															userProject.project
																.name
														}
													</Tag>
													<Tag
														style={{
															width: "80px",
															textAlign: "center",
															padding: "5px 10px",
														}}
													>
														{userProject.workType}
													</Tag>
													<Form.Item
														name={[
															userProject.projectId,
															"amount",
														]}
														initialValue={
															userProject.hourRate
																.amount
														}
														className="form-item-rate"
													>
														<InputNumber
															value={
																userProject
																	.hourRate
																	.amount
															}
															min={0}
															placeholder="Amount"
															disabled={
																userProject.workType ===
																"part-time"
															}
														/>
													</Form.Item>
													/
													<Form.Item
														name={[
															userProject.projectId,
															"projectHours",
														]}
														initialValue={
															userProject.projectHours
														}
														className="form-item-rate"
													>
														<InputNumber
															value={
																userProject.projectHours
															}
															min={0}
															placeholder="Hours"
															disabled={true}
														/>
													</Form.Item>
													=
													<Form.Item
														name={[
															userProject.projectId,
															"hourRate",
														]}
														initialValue={
															userProject.hourRate
																.rate ?? 0
														}
														className="form-item-rate"
													>
														<InputNumber
															value={
																userProject
																	.hourRate
																	.rate ?? 0
															}
															min={0}
															style={{
																width: 140,
															}}
															placeholder="Rate"
														/>
													</Form.Item>
												</Space>
											);
										}
									)}
								</Form.Item>
							</Form>
						</div>
					</Modal>
				</div>
			</div>
			<Table
				columns={columns}
				dataSource={users}
				onChange={onChange}
				loading={isUsersLoading}
			/>
		</>
	);
}

export default HourRates;
