import { useAxios } from "./axiosService";

export const useUsersReportingService = () => {
	const { axios } = useAxios();

	const getUsersReporting = async (date) => {
		const user = await axios.get(`/users-reporting?date=${date}`);
		return user;
	};

	const getUserReporting = async (userId, date) => {
		const user = await axios.get(`/users-reporting/${userId}?date=${date}`);
		return user;
	};

	return {
		getUsersReporting,
		getUserReporting,
	};
};
