import { useEffect } from "react";
import axios from "axios";
import { getToken, updateToken } from "./tokenService";
import { memoizedRefreshToken } from "./refreshTokenService";
import { TOKEN } from "../common/constants/localStorage";

function authInterceptor(config) {
	const finalConfig = { ...config };
	const token = getToken(TOKEN);

	if (!finalConfig.headers) {
		finalConfig.headers = {};
	}
	if (token) {
		finalConfig.headers.Authorization = `Bearer ${token}`;
	}

	return finalConfig;
}

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
});

instance.interceptors.request.use(authInterceptor);

instance.interceptors.response.use(
	(res) => {
		return res;
	},
	async (err) => {
		const originalConfig = err.config;

		// Access Token was expired
		if (err.response.status !== 401) {
			return Promise.reject(err);
		}

		// Refreshing token only once
		const result = await memoizedRefreshToken();

		updateToken(TOKEN, result);

		if (result) {
			originalConfig.headers = {
				...originalConfig.headers,
				Authorization: `Bearer ${result}`,
			};
		}

		return instance(originalConfig);
	}
);

export const axiosInstance = instance;

export const CancelSignal = axios.CancelToken.source;

export const isCancelSignal = (e) => axios.isCancel(e);

export const useAxios = () => {
	const signal = CancelSignal();

	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		return () => {
			signal.cancel("Unmount useAxios");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { axios: axiosInstance, cancelToken: signal.token };
};
