export const SELECT_WORKING_HOURS = [
	{
		value: 5,
		label: "5 hours",
	},
	{
		value: 6,
		label: "6 hours",
	},
	{
		value: 7,
		label: "7 hours",
	},
	{
		value: 8,
		label: "8 hours",
	},
	{
		value: 9,
		label: "9 hours",
	},
];
