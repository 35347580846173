import mem from "mem";

import { getToken, removeToken } from "./tokenService";
import { axiosInstance } from "./axiosService";
import { updateToken } from "./tokenService";
import { REFRESH_TOKEN, TOKEN } from "../common/constants/localStorage";

const refreshTokenFn = async () => {
	try {
		const response = await axiosInstance.post("/auth/refresh-token", {
			refreshToken: getToken(REFRESH_TOKEN),
		});

		if (!response.data) {
			removeToken(TOKEN);
			removeToken(REFRESH_TOKEN);
		}

		updateToken(TOKEN, response.data);

		return response.data;
	} catch (error) {
		removeToken(TOKEN);
		removeToken(REFRESH_TOKEN);
		window.location.replace("/auth/sign-in");
	}
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshTokenFn, {
	maxAge,
});
