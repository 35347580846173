import { Avatar, Table, Button, DatePicker } from "antd";
import { useEffect, useState } from "react";
import { useUserService } from "../../services/userService";
import { useUsersReportingService } from "../../services/usersRepostingService";
import "./Compensation.scss";
import { BankOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { ADMIN_ROLES } from "../../common/constants/adminRoles";

function Compensation() {
	const navigate = useNavigate();
	const { currentUser } = useAuth();

	const userService = useUserService();
	const usersReportingService = useUsersReportingService();

	const [usersReporting, setUsersReporting] = useState();
	const [isUsersLoading, setIsUsersLoading] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());

	useEffect(() => {
		fetchUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDate]);

	const fetchUsers = async () => {
		try {
			setIsUsersLoading(true);

			const users = currentUser?.userRoleNames?.some((role) =>
				ADMIN_ROLES.includes(role)
			)
				? (await userService.getAllUsers()).data
				: [currentUser];

			const mappingUsers = users.map((user) => ({
				...user,
				name: `${user.firstName} ${user.lastName}`,
				roleNames: user.userRoles.map((userRole) => userRole.role.name),
				roleIds: user.userRoles.map((userRole) => userRole.role.id),
				key: user.id,
			}));

			const usersReporting = currentUser?.userRoleNames?.some((role) =>
				ADMIN_ROLES.includes(role)
			)
				? await usersReportingService.getUsersReporting(selectedDate)
				: await usersReportingService.getUserReporting(
						currentUser.id,
						selectedDate
				  );

			setUsersReporting(
				usersReporting.data.usersReporting.map((userReporting) => {
					const user = mappingUsers?.find(
						(user) => user.email === userReporting.email
					);

					return { ...userReporting, ...user };
				})
			);

			setIsUsersLoading(false);
		} catch (e) {
			console.error(e);
		}
	};

	const onChangeDate = (value) => {
		setSelectedDate(dayjs(value).toDate());
	};

	const onChange = (value) => {
		console.log("params", value);
	};

	const onCLickInvoiceUser = (record) => {
		navigate(
			`/invoice/${record?.id}/${dayjs(selectedDate).format("DD-MM-YYYY")}`
		);
	};

	const columns = [
		{
			title: "",
			dataIndex: "photo",
			key: "photo",
			width: "50px",
			render: (photo) => <Avatar size="large" src={`${photo}`} />,
		},
		{
			title: "Name",
			dataIndex: "name",
			onFilter: (value, record) => record.name.indexOf(value) === 0,
			sorter: (a, b) => a.name.length - b.name.length,
			sortDirections: ["descend"],
			key: "name",
		},
		{
			title: "Absences hours",
			dataIndex: "absencesHours",
			key: "absencesHours",
			render: (hours) => <div style={{ fontSize: 15 }}>{hours}</div>,
		},

		{
			title: "Non-paid hours",
			dataIndex: "nonPaidHours",
			key: "nonPaidHours",
			render: (hours) => (
				<div style={{ color: hours > 0 && "red", fontSize: 15 }}>
					{hours}
				</div>
			),
		},

		{
			title: "Actions",
			dataIndex: "actions",
			key: "actions",
			width: "10%",
			render: (text, record) => (
				<div>
					<Button
						onClick={() => onCLickInvoiceUser(record)}
						icon={<BankOutlined />}
					/>
				</div>
			),
		},
	];

	return (
		<div style={{ padding: "10px 200px" }}>
			<DatePicker
				style={{ marginBottom: "20px", width: "200px" }}
				onChange={onChangeDate}
				picker="month"
				format={"MMMM, YYYY"}
				defaultValue={dayjs()}
				allowClear={false}
			/>
			<Table
				columns={columns}
				dataSource={usersReporting}
				onChange={onChange}
				loading={isUsersLoading}
			/>
		</div>
	);
}

export default Compensation;
