import { GoogleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function SignInAdminButton({ scope }) {
	let windowObjectReference = null;
	let previousUrl = null;

	useEffect(() => {
		return () => {
			window.removeEventListener("message", receiveMessage, false);
		};
	}, []);

	const [loading, setLoading] = useState(false);
	const { setAuth } = useAuth();

	function encodeQueryData(data) {
		const ret = [];
		for (let d in data) {
			ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
		}

		return ret.join("&");
	}

	const getCode = () => {
		const queryData = {
			scope: scope,
			access_type: "offline",
			// 'include_granted_scopes': 'true',
			response_type: "code",
			redirect_uri: `${BASE_URL}/auth/callback`,
			client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
		};
		const queryString = encodeQueryData(queryData);

		const url = `https://accounts.google.com/o/oauth2/v2/auth?${queryString}`;

		openSignInWindow(url, "login-redirect");
	};

	const openSignInWindow = (url, name) => {
		// Remove any existing event listeners
		window.removeEventListener("message", receiveMessage, false);

		// Window features
		const strWindowFeatures =
			"toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

		if (windowObjectReference === null || windowObjectReference.closed) {
			/* If the pointer to the window object in memory does not exist
             or if such pointer exists but the window was closed */
			windowObjectReference = window.open(url, name, strWindowFeatures);
		} else if (previousUrl !== url) {
			/* If the resource to load is different,
             then we load it in the already opened secondary window and then
             we bring such window back on top/in front of its parent window. */
			windowObjectReference = window.open(url, name, strWindowFeatures);
			windowObjectReference.focus();
		} else {
			/* Else the window reference must exist and the window
             is not closed; therefore, we can bring it back on top of any other
             window with the focus() method. There would be no need to re-create
             the window or to reload the referenced resource. */
			windowObjectReference.focus();
		}

		// Add the listener for receiving a message from the popup
		window.addEventListener("message", receiveMessage, false);
		// Assign the previous URL
		previousUrl = url;
	};

	const receiveMessage = useCallback(async (event) => {
		// Check if we could trust the event origin
		if (event.origin !== BASE_URL) {
			return;
		}

		const { data } = event;
		// Check if the source is our popup
		if (event.source.name === "login-redirect") {
			setLoading(true);

			let params = new URLSearchParams(data);
			const code = params.get("code");

			const tokenData = await axios.post(`${API_BASE_URL}/auth/google`, {
				code: code,
			});
			setLoading(false);

			setAuth(tokenData.data.id_token, tokenData.data.refresh_token);
		}
	}, []);

	return (
		<Button
			loading={loading}
			onClick={getCode}
			type="primary"
			icon={<GoogleOutlined />}
			size={"middle"}
		>
			Sign-in as admin
		</Button>
	);
}

export default SignInAdminButton;
