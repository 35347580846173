export const getToken = (tokenName) =>
	JSON.parse(localStorage.getItem(tokenName));

export const updateToken = (tokenName, token) => {
	localStorage.setItem(tokenName, JSON.stringify(token));
};

export const removeToken = (tokenName) => {
	localStorage.removeItem(tokenName);
};
