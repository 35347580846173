import React from "react";
import { Outlet } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import SignIn from "./pages/SignIn/SignIn";
import UsersList from "./pages/UsersList/UsersList";
import Callback from "./components/auth/Callback";
import GuardRoute from "./components/auth/GuardRoute";
import { AuthLayout } from "./components/auth/AuthLayout";
import Dashboard from "./pages/Dashboard/Dashboard";
import Projects from "./pages/Projects/Projects";
import Compensation from "./pages/Compensation/Compensation";
import HourRates from "./pages/HourRates/HourRates";
import Invoice from "./pages/Invoice/Invoice";
import ProtectedRoute from "./routes/ProtectedRoute";

const router = createBrowserRouter([
	{
		path: "/",
		element: (
			<AuthLayout>
				<GuardRoute>
					<App />
				</GuardRoute>
			</AuthLayout>
		),
		children: [
			{
				path: "dashboard",
				element: <Dashboard />,
			},
			{
				path: "users-list",
				element: (
					<ProtectedRoute>
						<UsersList />
					</ProtectedRoute>
				),
			},
			{
				path: "projects",
				element: (
					<ProtectedRoute>
						<Projects />
					</ProtectedRoute>
				),
			},
			{
				path: "compensation",
				element: <Compensation />,
			},
			{
				path: "hour-rate",
				element: (
					<ProtectedRoute>
						<HourRates />
					</ProtectedRoute>
				),
			},
			{
				path: "invoice/:userId/:date",
				element: <Invoice />,
			},
		],
	},
	{
		path: "/auth",
		element: (
			<>
				<Outlet />
			</>
		),
		children: [
			{
				path: "sign-in",
				element: (
					<AuthLayout>
						<SignIn />
					</AuthLayout>
				),
			},
			{
				path: "callback",
				element: <Callback />,
			},
		],
	},
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
