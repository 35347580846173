import React, { FC } from "react";
// import { Page as PdfPage } from '@react-pdf/renderer'
// import compose from '../styles/compose'

const Page = ({ className, children }) => {
	return (
		<div className={"page " + (className ? className : "")}>{children}</div>
	);
};

export default Page;
