import { Input } from "antd";
import React, { FC } from "react";
// import { Text } from '@react-pdf/renderer'
// import compose from '../styles/compose'

const EditableInput = ({ style, placeholder, value, onChange }) => {
	return (
		<Input
			placeholder={placeholder || ""}
			value={value || ""}
			style={{
				...style,
				borderColor: "transparent",
				backgroundColor: "transparent",
			}}
			onChange={onChange ? (e) => onChange(e.target.value) : undefined}
		/>
	);
};

export default EditableInput;
