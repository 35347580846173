import {useEffect} from "react";

function Callback() {
    useEffect(() => {
        // Get the URL parameters which will include the code
        const params = window.location.search;
        if (window.opener) {
            // Send them to the opening window
            window.opener.postMessage(params);
            // Close the popup
            window.close();
        }
    }, []);

    return <p>Please wait...</p>;
}

export default Callback;
